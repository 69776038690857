.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* For webkit-based browsers (Chrome, Safari) */
/* Customize scrollbar */
::-webkit-scrollbar {
  width: 3px; /* width of the scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* color of the scrollbar handle */
  border-radius: 6px; /* roundness of the scrollbar handle */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the scrollbar track */
}

/* For Firefox */
/* Customize the scrollbar */
/* width */
html {
  scrollbar-width: thin; /* width of the scrollbar */
}

/* Handle */
html {
  scrollbar-color: #888 #f1f1f1; /* color of the scrollbar handle and track */
}
